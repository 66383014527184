import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { color, mixin, breakpoint } from '@src/settings';

const paddingV = 30;
const paddingH = 50;
const rowGap = 2;

export const TableCont = styled.div`
	box-shadow: 0 0 1rem 0.4rem rgba(0,0,0,0.05);
`;

export const TableTitle = styled.h3`
	font-size: 1.6rem;
	line-height: 6.3rem;
	font-weight: bold;
	color: white;
	background: ${color.blue};
	padding-left: 2.87rem;
`

export const Row = styled.dl`
	display:flex;
	align-items: flex-start;
	margin-top: ${ rowGap*0.1 }rem;
	background: #FFF;
	${ media.lessThan(`${ breakpoint.sp }px`)`
		display: block;
		padding: 2rem 2.5rem;
	`}
`
export const RowTitle = styled.dt`
	align-self: stretch;
	flex: 19rem 0 0;
	max-width: 19rem;
	font-size: 1.6rem;
	color: ${color.blue};
	padding: ${ paddingV*0.1 }rem  ${ paddingH*0.1 }rem;
	position: relative;
	${ props => props.history && `
		&:before{
			content=""
			display: block;
			content: "";
			width: 0.2rem;
			${ mixin.borderedBg("0deg", color.blue, 2, 2) }
			position: absolute;
			top: ${ props.isFirst ? "50%" : 0 };
			bottom: ${ props.isLast ? 0 : rowGap*-0.1 }rem;
			right: 0;
			transform: translateX(50%);
		}
		&:after{
			conntent: "";
			display: block;
			content: "";
			width: 1rem;
			height: 1rem;
			border: 0.1rem solid ${color.blue};
			border-radius: 100%;
			background-color: #FFF;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(50%,-50%);
		}
	`}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		margin-bottom: 0.5rem;
		padding: 0;
		${ props => props.history && `
			&:before,
			&:after{
				content: none;
			}
		`}
	`}
`
export const RowData = styled.dd`
	flex: auto 1 1;
	font-size: 1.6rem;
	color: ${color.txtLight};
	padding: ${ paddingV*0.1 }rem ${ paddingH*0.1 }rem;
	& > iframe {
		margin-top: 2.45rem;
		height: 27.8rem;
		width: 100%;
	}
	& > .translation{
		color: #7b808a;
		font-size: 1.35rem;
	}
	& > p + p{
		margin-top: 0.5rem;
	}
	& > .translation + p{
		margin-top: 1.8rem;
	}
	b{
			font-size: 1.1em;
			color:${color.blue};
			font-weight:700;
		margin-right: 0.3rem;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		padding: 0;
	`}
`
