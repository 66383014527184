import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { graphql, StaticQuery} from 'gatsby';
import Image from 'gatsby-image';
import Hero from '@components/common/hero';
import ContactIntro from '@components/common/contact-intro';
import { HeroTitle } from '@components/company/svgs';
import Table from '@components/company/table';
import Layout from '@components/common/layout';
import { Tab, TabItem } from '@components/common/styles';
import { color, mixin, breakpoint } from '@src/settings';

const Company_page = ({ data }) => (
	<Layout baseBgColor={ color.paleGray }>
		<main>
			<Hero
				img={ data['heroImg'] }
				title={<HeroTitle style={{ fill: "#FFF" }}/>}
				subTitle="会社概要・沿革"
			/>
			<Tab>
				<TabItem to="/message"><span>Message</span><span>代表挨拶</span></TabItem>
				<TabItem to="/company" className="isActive"><span>Company</span><span>会社概要・沿革</span></TabItem>
			</Tab>
			<Container>
				<Table
					className="table"
					title="企業理念"
					list={[
						{
							title: "PHILOSOPHY",
							data: "TECHNOLOGY DRIVES TOMORROW"
						},
						{
							title: "VISION",
							data: <>
								<p>Aim to be a Manufacturer for Technology Components with Global Competitiveness to support our National Industry</p>
								<p className="translation">国の産業を支えるグローバル競争力のある技術部品メーカーを目指す</p>
							</>
						},
						{
							title: "MISSION",
							data: <>
								<p><b>L</b>ong term relationship with customers, suppliers, and employees by creating triple win situation based on integrity</p>
								<p className="translation">誠実さを基に顧客、サプライヤー、従業員のトリプルウィンを創ることにより長期的な関係を築く</p>
								<p><b>S</b>ocial Responsibility being carried out by creating sustainable working environment and continually contribute to the society</p>
								<p className="translation">サステナブルな職場環境を作り、継続的な社会貢献により社会的責任を果たす</p>
								<p><b>F</b>ulfilment of customer satisfaction being prioritized and achieved by constantly striving for excellence</p>
								<p className="translation">常に卓越性を追求することで、最優先である顧客満足の実現を達成する</p>
							</>
						},
						{
							title: "OUR CORE VALUE",
							data: <>
								<p><b>C</b>ustomer First</p>
								<p><b>E</b>xcellence Kaizen</p>
								<p><b>R</b>espect</p>
								<p><b>I</b>ntegrity</p>
								<p><b>A</b>ccountability</p>
							</>
						},
					]}
				/>
				<Table 
					className="table"
					title="会社概要"
					list={[
						{
							title: "所在地",
							data: <>
								<p>Lot 5747, Kidamat Industrial Park, <br/>Bukit Angkat, <br />43000 Kajang, Selangor Darul Ehsan, Malaysia</p>
								<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15937.407800463345!2d101.7622671!3d2.9995782!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x88477d01432f139b!2sLSF%20Technology%20Sdn%20Bhd!5e0!3m2!1sja!2sjp!4v1585289745680!5m2!1sja!2sjp" width="600" height="450" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
							</>
						},
						{
							title: "設立",
							data: "2000年4月"
						},
						{
							title: "資本金",
							data: "26.5million リンギット（約6億5185万円）"
						},
						{
							title: "代表者",
							data: "代表取締役　Dato' Stan Lee Chan Fai"
						},
						{
							title: "従業員数",
							data: "447名（2019年11月現在）"
						},
					]}
				/>
				<Table 
					className="table"
					history
					title="会社沿革"
					list={[
						{
							title:"2000年4月",
							data:"LSF Technology設立"
						},
						{
							title:"2002年1月",
							data:"CO2溶接ロボット溶接を導入し、組立部品の製造を開始"
						},
						{
							title:"2004年1月",
							data:"ISO9001認証取得"
						},
						{
							title:"2007年4月",
							data:"一次サプライヤーとしてトヨタ車体マレーシア社（TABM）との取引を開始"
						},
						{
							title:"2007年7月",
							data:"大型CNC機を導入し、B級金型の製造を開始"
						},
						{
							title:"2009年5月",
							data:"2本の160T級のプレスラインを追加し生産拡大に対応"
						},
						{
							title:"2010年4月",
							data:"試作業務の拡大のため、5軸レーザーカット機を導入"
						},
						{
							title:"2011年3月",
							data:"3万平米の土地を取得しKAJANGに新工場を建築し、本社を移転"
						},
						{
							title:"2012年1月",
							data:"1000Tトライプレス機を導入"
						},
						{
							title:"2012年6月",
							data:"一次サプライヤーとしてPerodua社との取引を開始"
						},
						{
							title:"2013年1月",
							data:"ISO14001認証取得"
						},
						{
							title:"2014年9月",
							data:"KAJANG本社に第二工場を設立"
						},
						{
							title:"2014年11月",
							data:"500T級プレスラインを導入し、B級プレス部品の製造を開始"
						},
						{
							title:"2015年4月",
							data:"一次サプライヤーとしてProton社との取引を開始"
						},
						{
							title:"2015年7月",
							data:"現社長、Dato’ Stan Lee社長に就任"
						},
						{
							title:"2015年12月",
							data:"2000T油圧プレス機及びレーザーカット機３台を導入し外板部品の製造を開始"
						},
						{
							title:"2016年11月",
							data:"600T級オートメーションプレス機を導入し、生産拡大に対応"
						},
						{
							title:"2017年4月",
							data:"TS16949準拠企業として認定"
						},
						{
							title:"2018年4月",
							data:"溶接ロボットを５台導入し、リアアンダー部品の生産に対応"
						},
					]}
				/>
			</Container>
			<ContactIntro/>
		</main>
	</Layout>
)

export default Company_page

const Container = styled.div`
	margin: 8.5rem 0; 
	${ mixin.baseWidthSet }
	.table + .table{
		margin-top: 8rem;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		margin-top: 5rem;
		margin-bottom: 6rem;
		.table + .table{
			margin-top: 3rem;
		}
	`}
`;

export const query = graphql`
	query companyImgs{
		heroImg: file( relativePath: { eq: "company/hero-img.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
	}
`
