import React from 'react';
import { TableCont, TableTitle, Row, RowTitle, RowData } from './styles';

type Prop = {
	title :string,
	data :string | React.ReactElement,
	history: boolean,
	isFirst: boolean,
	isLast: boolean,
}

const TableInfo: React.FC<Props> = props => (
	<Row>
		<RowTitle
			history={ props.history }
			children={ props.title }
			isFirst={ props.isFirst }
			isLast={ props.isLast }
		/>	
		<RowData children={ props.data }/>
	</Row>
)


const Table = ({ title, list, className, history }) => (
	<TableCont className={ className }>
		<TableTitle>{ title }</TableTitle>
		{ list && list.map((info,index) => {
		return (
			<TableInfo
				title={ info.title }
				data={ info.data }
				history={ history && history }
				isFirst={ index == 0 }
				isLast={ index == list.length-1 }
				key={ index }
			/>
		)})}
	</TableCont>
)

export default Table;
