import React from 'react';

export const HeroTitle = (props) => (
	<svg { ...props } viewBox="0 0 476 34">
		<g>
			<path d="M1.2 16.9C1.2 7.9 8 .5 17.6.5c5.9 0 9.5 2 12.4 4.9l-4.4 5.1c-2.4-2.2-4.9-3.6-8.1-3.6-5.3 0-9.1 4.4-9.1 9.8v.1c0 5.4 3.7 9.9 9.1 9.9 3.6 0 5.8-1.4 8.3-3.7l4.4 4.5c-3.2 3.5-6.8 5.6-12.9 5.6-9.2 0-16.1-7.2-16.1-16.2z"/>
			<path d="M33.5 16.9c0-9 7.1-16.4 16.8-16.4C60 .5 67 7.8 67 16.7v.1c0 9-7.1 16.3-16.8 16.3s-16.7-7.3-16.7-16.2zm26.3 0c0-5.5-4-10-9.5-10-5.6 0-9.4 4.4-9.4 9.8v.1c0 5.4 4 9.9 9.5 9.9s9.4-4.4 9.4-9.8zM73.2 1h7.5L89 14.3 97.2 1h7.5v31.5h-6.9V12l-8.9 13.5h-.2L80 12.1v20.4h-6.8V1z"/>
			<path d="M112.2 1h12.9c7.5 0 12.1 4.5 12.1 10.9v.1c0 7.3-5.7 11.1-12.7 11.1h-5.3v9.4h-6.9V1zm12.4 15.9c3.5 0 5.5-2.1 5.5-4.8V12c0-3.1-2.2-4.8-5.6-4.8h-5.4v9.6h5.5z"/>
			<path d="M150.4.8h6.4l13.5 31.7H163l-2.9-7.1h-13.3l-2.9 7.1h-7.1L150.4.8zm7.3 18.6l-4.2-10.2-4.2 10.2h8.4zM175.1 1h6.4l14.8 19.4V1h6.8v31.5h-5.9l-15.3-20v20h-6.8V1zM219.6 20.1L207.4 1h8.1l7.5 12.6L230.7 1h7.9l-12.1 19v12.6h-6.9V20.1z"/>
		</g>
	</svg>
)
