import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'gatsby';
import { size, mixin, color, breakpoint } from '@src/settings';

export const Box = styled.article`
	margin-top: ${ size.pageFirstGap*0.1 }rem;
	margin-bottom: ${ size.pageLastGap*0.1 }rem;
	${ mixin.baseWidthSet }
	background-color: #FFF;
	padding: 8rem;
`;
export const Tab = styled.nav`
	display: flex;
	width: 100%;
	box-shadow: 0 0 10px 4px rgba(0,0,0,0.08);
`;

export const TabItem = styled(Link)`
	flex: 50% 1 1;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #FFF;
	padding: 1.7rem 0 1.9rem;
	font-size: 1.7rem;
	line-height: 1;
	color: ${ color.blue };
	&.isActive{
		background-color: #f1f2f4;
	}
	& > span{
		&:nth-child(2){
			display: block;
			font-size: 1.2rem;
			margin-left: 1rem;
			color: ${ color.paleBlue };
			transform: translateY(15%);
		}
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		& > span{
			&:nth-child(1){
				display: none;
			}
			&:nth-child(2){
				font-size: 1.3rem;
				transform: none;
				margin-left: 0;
			}
		}
	`};
`;
